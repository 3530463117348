@font-face {
	font-family: 'coolvetica';
	font-weight: 400;
	font-style: normal;
	font-display: auto;
	src: url('../fonts/coolvetica.otf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 100;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraLight.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 100;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraLightItalic.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-Italic.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 300;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-Light.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 300;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-LightItalic.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-Medium.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 500;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-MediumItalic.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-Regular.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 600;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-SemiBold.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 600;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-SemiBoldItalic.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-Bold.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 700;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-BoldItalic.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 900;
	font-style: normal;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraBold.ttf');
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	font-display: auto;
	font-weight: 900;
	font-style: italic;
	src: url('../fonts/Plus Jakarta Sans/PlusJakartaSans-ExtraBoldItalic.ttf');
}
