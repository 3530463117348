html {
	scroll-behavior: smooth;
}

body {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: Plus Jakarta Sans;
	color: $white-color;
	overflow-x: hidden;
	background-color: $bg-color;
}

a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #eb3c5b;
}

.header-section {
	height: 90px;
	.logo {
		height: 60px;
	}
	ul {
		list-style: none;
		padding: 0;
		li {
			padding: 20px;
			font-size: 16px;
		}
		.active {
			color: #eb3c5b;
			&:hover {
				color: #fff;
			}
		}
	}
	.menu {
		width: 30px;
		height: 30px;
	}
}

.hero-image {
	position: absolute;
	right: 0px;
}

.rectangle-image {
	position: absolute;
	top: -77px;
	right: 80px;
	width: 266px;
	height: 832px;
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.header-btn-conatiner {
	width: 100%;
	position: fixed;
	bottom: 0px;
	left: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	&.fade-out {
		opacity: 0;
		animation: fade-out 0.2s ease;
	}
	&.fade-in {
		opacity: 1;
		animation: fade-in 0.2s ease;
	}
}
.button-container {
	background-color: transparent;
	transition: 1s all ease-out;
	.button-wrapper {
		margin: 0;
		.download-btn {
			display: flex;
			align-items: center;
			.download-animation {
				width: 50px;
				height: 45px;
			}
		}
	}
	&.whatever {
		left: 27px;
		bottom: 13px;
	}
}

.top-banner-section {
	margin-top: 70px;
	position: relative;
	z-index: 2;
	// padding-top: 130px;
	width: 100%;

	.banner-contant {
		width: 50%;
		h3 {
			margin: 0px 0px 20px 0px;
		}
		input {
			padding-left: 17px;
			width: 373px;
			height: 52px;
			border: none;
			outline: none;
			background-color: $light-black;
			margin-right: 10px;
			box-shadow: -2px 0px 0px #eb3c5b;
		}
	}
	.banner-image {
		width: calc(60% - 87px);
		margin-left: 87px;
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
	.banner-ludo-img {
		height: 538px;
	}
	.tictac-banner-img {
		height: 474px;
	}
}

.benifits-section {
	margin-top: 100px;
	background: linear-gradient(90deg, rgba(5, 17, 33, 0) 0%, #051121 30%, #051121 70%, rgba(5, 17, 33, 0) 100%);
	padding: 40px;
}

.benifit-icons-wrapper {
	margin-top: 0px;
	.benifits-icons {
		margin: 0 15px;
	}
}

.ball-pool-benefit-section {
	margin-top: 100px;
}

.refer-earn-step-wrapper {
	.step-card {
		padding: 0 20px 40px;
	}
	gap: 30px;
}
.play-winning-section {
	margin-top: 80px;
	.winning-wrapper {
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.step-card {
			border: 0.5px solid rgba(255, 255, 255, 0.5);
			display: flex;
			background: $bg-color;
			width: calc(50% - 22px);
			margin-bottom: 40px;

			.step-card-img-wrap {
				height: 100%;
				display: flex;
				align-items: center;
				border-radius: 30px 0 0 30px;
				img {
					margin: 20px;
				}
			}
			.step-card-content {
				border-radius: 0px 30px 30px 0px;
				padding: 30px 50px 30px 30px;
				background-color: #242b33;
				p {
					font-size: 18px;
					line-height: 32px;
				}
				h1 {
					margin: 0;
					margin-bottom: 30px;
				}
			}
		}
	}
}

.winning-section {
	.refer-earn {
		border-radius: 30px;
		height: 100%;
	}
	.withdrow {
		background: #1c1c1c;
		border-radius: 30px;
		padding: 24px 0px 0px;
		min-height: 237px;
	}
}

.upcomming-section {
	margin-top: 70px;
	.game-image {
		width: 455px;
		height: 100%;
		transition: all 0.5s ease-out;
		margin: 0px 7px;
	}

	.slick-track {
		display: flex;
		.slick-slide {
			div {
				outline: none;
			}
		}
	}

	.slick-prev {
		top: -66px;
		left: auto;
		right: 85px;
		&::before {
			content: '';
		}
	}

	.slick-next {
		top: -66px;
		right: 26px;
		&::before {
			content: '';
		}
	}
}

.benifits-section {
	.payment-wrapper {
		.slick-slider {
			width: 100%;

			.slick-prev {
				&::before {
					display: none;
				}
			}
			.slick-next {
				&::before {
					display: none;
				}
			}
			.slick-dots {
				bottom: -50px;
				li {
					button {
						&::before {
							color: #8d8d8d !important;
							font-size: 16px !important;
						}
					}
				}
			}
		}
	}
}

.deposit-winning-section {
	background: linear-gradient(90deg, rgba(28, 28, 28, 0) -7.32%, #1c1c1c 13.15%, rgba(28, 28, 28, 0.844846) 88.85%, rgba(28, 28, 28, 0) 100.5%);

	h1 {
		font-size: 50px;
	}
	.payment-wrapper {
		.slick-slider {
			width: 100%;

			.slick-prev {
				&::before {
					display: none;
				}
			}
			.slick-next {
				&::before {
					display: none;
				}
			}
			.slick-dots {
				bottom: -50px;
				li {
					button {
						&::before {
							color: #8d8d8d !important;
							font-size: 16px !important;
						}
					}
				}
			}
		}
	}
}

.faqs-section {
	.faqs-btn-margin {
		margin-top: 80px;
	}
	.more-faq-btn {
		&:hover {
			background: #eb3c5b;
			border: 1px solid #eb3c5b;
		}
	}
	.faq-btn {
		&:hover {
			background: #eb3c5b;
			color: white;
			border: 1px solid #eb3c5b;
		}
	}
	margin-top: 100px;
	.title {
		margin-bottom: 50px;
	}
	&.faq-full-width {
		.faqs-content-wrapper {
			.faqs-content {
				width: 100%;
			}
		}
	}
	.faqs-content-wrapper {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		.faqs-content {
			background: $light-blue-color;
			border-radius: 10px;
			width: 966px;
			padding: 10px 18px 10px 26px;

			p {
				margin: 0;
			}

			.block {
				background: $dark-color;
				border-radius: 4px;
				width: 32px;
				height: 32px;

				&:hover {
					background-color: #eb3c5b;
				}
				&:hover {
					span {
						svg {
							fill: black;
						}
					}
				}
			}

			.inner-content {
				max-width: 1250px;
				transition: max-height 0.3s ease-in-out;
				overflow-y: hidden;
			}
		}

		.active {
			color: white;
		}
	}
	.faqs-btn-wrap {
		margin-top: 80px;
	}
}

.footer-section {
	margin-top: 100px;
	background: $dark-blue-color;
	.footer-top-wrapper {
		padding: 43px 20px 28px 20px;
		.social-icon-membership-wrapper {
			width: calc(45% - 100px);
			display: flex;
			justify-content: space-between;
			.social-icon-wrapper {
				p {
					margin-top: 0px;
					margin-bottom: 5px;
				}
				.social-icon {
					.social-media--icon {
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}

		.footer-link-wrapper {
			p {
				margin-top: 0px;
			}
			ul {
				margin-top: 24px;
			}
		}

		.policy-wrapper {
			width: calc(55% - 100px);

			ul {
				padding: 0;

				li {
					list-style: none;
				}
			}
		}

		.payment-wrapper {
			width: 15%;
			.payment {
				width: 65%;
				.icon-wrapper {
					width: 40px;
					height: 40px;
					background: $white-color;
					border-radius: 11px;

					svg {
						width: 30px;
						height: 30px;
					}
				}
				.cash-payment {
					width: 100% !important;
					img {
						border-radius: 6px !important;
					}
				}
			}
		}
	}

	.footer-line {
		border: 1px solid #d9d9d9;
		opacity: 0.2;
	}

	.copyright-text {
		margin: 0;
	}

	.link-title {
		font-weight: 300;
		font-size: 26px;
		line-height: 30px;
	}
	.footer-input {
		box-shadow: -2px 0px 0px #eb3c5b;
		padding-left: 17px;
		width: 373px;
		height: 52px;
		border: none;
		outline: none;
		background-color: #1c1c1c;
		margin-right: 15px;
	}
	// .download-button {
	// 	border: 4px solid rgba(71, 28, 36, 0.5);
	// 	display: flex;
	// 	justify-content: space-around;
	// 	align-items: center;
	// 	width: 219px;
	// 	height: 52px;
	// 	font-size: 21px;
	// 	.download-icon {
	// 		width: 34px;
	// 		height: 32px;
	// 	}
	// }
}

.privacy-wrapper {
	margin-top: 107px;
	margin-bottom: 100px;
	text-align: justify;

	.info-wrapper {
		margin-top: 70px;

		h3 {
			margin: 0px;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.02em;
		}
		p {
			margin: 0px;
			font-weight: 400;
			font-size: 16px;
			line-height: 32px;
			letter-spacing: 0.02em;
			margin-top: 21px;

			li {
				list-style-type: decimal;
			}
		}
	}
}

.breadcrumb {
	margin-bottom: 0;
	padding: 0;
	text-transform: capitalize !important;
	border-radius: 4px;
	list-style: none;
	outline: none;
	li {
		font-weight: 400;
		font-size: 20px;
		line-height: 50px;
		display: inline-block;
		color: #ffffff;
		letter-spacing: 0.01em;
		a {
			color: #ffffff;
			text-decoration: none;
		}
	}
	p {
		font-weight: 400;
		display: inline-block;
		color: #ffffff;
		padding: 0 4px;
	}
}

.terms-condition-wrapper {
	margin-top: 70px;
	margin-bottom: 100px;
	.terms-wrapper {
		text-align: justify;

		h2 {
			margin: 0px;
			line-height: 38px;
			font-size: 26px;
		}
		h3 {
			margin: 0px;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.02em;
		}
		p {
			margin: 21px 0px 0px 0px;
			font-size: 18px;
			line-height: 32px;
			font-weight: 400;
			letter-spacing: 0.02em;
			color: #9b9b9b;
		}
		li {
			font-size: 16px;
			line-height: 32px;
			color: #9b9b9b;
			padding: 5px 0px;
		}
	}
	.info-wrapper {
		text-align: justify;
		margin-top: 70px;
		h3 {
			margin: 0px;
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.02em;
		}
		p {
			margin: 21px 0px 0px 0px;
			font-weight: 400;
			font-size: 16px;
			line-height: 32px;
			letter-spacing: 0.02em;
			color: #9b9b9b;

			ol {
				list-style-type: lower-latin;
			}
		}
	}
}

.sidenav-mobile-wrapper {
	.sidenav {
		position: fixed;
		top: 0;
		right: 0;
		width: 60%;
		min-width: 300px;
		height: 100vh;
		z-index: 9999;
		background: #000812;
		transition: all 0.5s linear;

		.close-icon {
			position: absolute;
			top: 20px;
			right: 20px;
		}
		ul {
			list-style: none;
			padding: 0;
			li {
				padding: 20px;
				font-size: 16px;
			}
			.active {
				color: #eb3c5b;
				&:hover {
					color: #fff;
				}
			}
		}
		ul {
			margin-top: 50px;
			li.sidenav-item {
				padding: 0;
				a {
					display: block;
					text-decoration: none;
					font-size: 18px;
					font-weight: 700;
					padding: 20px;
				}
			}
		}
	}

	.overlay {
		width: 100%;
		height: 100vh;
		background-color: #17161a;
		opacity: 0.6;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
	}
}

.how-to-install-section {
	margin-top: 100px;
	.slick-slider {
		.slick-list {
			padding-left: 0px !important;
		}
	}

	.how-to-install-content {
		.right-arrow-img {
			width: 24px;
			height: 24px;
		}
	}
	.how-to-install-wrapper {
		.install-step-wrapper {
			.install-step {
				.stepper-steps {
					min-height: 158px;
					min-width: 703px;
					padding: 0 24px;
					background-color: $light-blue-color;
					border-radius: 30px;
					.install-step-img {
						width: 86px;
					}
					h5 {
						font-family: 'Plus Jakarta Sans';
					}
				}
			}
			.stepper-wrapper {
				.stepper-item {
					svg {
						z-index: 1111;
						rect {
							&:nth-child(2) {
								fill: #444444;
								&:active {
									fill: rgb(235, 60, 91);
								}
							}
						}
					}
				}
			}
		}
	}
	.mobile-image-wrapper {
		.mobile-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: flex;
			justify-content: space-between;

			.bg-circle {
				background-color: #eb3c5b;
				opacity: 0.2;
				width: 450px;
				height: 450px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				margin-top: 185px;
				.mobile {
					margin-top: -93px;
				}
			}
			.slick-slider {
				button {
					display: none !important;
				}
				.slick-list {
					border-radius: 30px;
					position: absolute;

					width: 278px;
					height: 602px;
					top: -709px;
					right: -139px;
				}
				.download-step {
					outline: none;
					border-radius: 30px;
				}
			}
			.mobile-frame {
				position: absolute;
				top: 2400px;
				right: 265px;
				z-index: 2;
			}
		}
		// .download-button {
		// 	display: flex;
		// 	margin: 0 auto;
		// 	justify-content: center;
		// 	align-items: center;
		// 	border: none;
		// 	.download-icon {
		// 		padding-left: 23px;
		// 	}
		// }
	}
}

.how-to-play-wrapper {
	.mobile-image-wrapper {
		.mobile-image {
			.download-app-img {
				outline: none;
			}
			.bg-circle {
				background-color: #eb3c5b;
				opacity: 0.2;
				width: 450px;
				height: 450px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				margin-top: -10px;
				.mobile {
					margin-top: -60px;
					outline: none;
				}
			}

			.slick-slider {
				button {
					display: none !important;
				}
				.slick-list {
					border-radius: 30px;
					outline: none;
					position: absolute;
					width: 278px;
					height: 602px;
					top: -499px;
					right: -139px;
					.download-step {
						border-radius: 23px;
						outline: none;
					}
				}
			}
		}
	}
	.play-step-wrapper {
		position: relative;
		.stepper-wrapper {
			gap: 30px;
			.play-stepper-item {
				&.active {
					svg {
						rect {
							&:nth-child(2) {
								fill: rgb(235, 60, 91) !important;
							}
						}
					}
				}
				svg {
					z-index: 1111;
					rect {
						&:nth-child(2) {
							fill: #444444;
							&:active {
								fill: rgb(235, 60, 91);
							}
						}
					}
				}
			}
		}
		.numbers-wrapper {
			height: 85%;
		}
		.progress-wrapper {
			margin: 0 15px;
		}
		.play-step {
			.stepper-steps {
				min-height: 158px;
				min-width: 703px;
				padding: 0 16px;
				background-color: $light-blue-color;
				border-radius: 30px;
				p {
					max-width: 462px;
					font-family: 'Plus Jakarta Sans';
					margin-right: 20px;
				}

				img {
					width: 86px;
				}
			}
		}
	}
	.play-stepper-item {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		&::after {
			position: absolute;
			content: '';
			border-image: linear-gradient(to right, #eb3c5b 53.12%, #0b0b0b 100%) 1;
			border-bottom: 2px solid #ccc;
			width: 430px;
			top: 321px;
			left: -133px;
			z-index: 100;
			transform: rotate(90deg);
		}
	}
}

.contact-us-wrapper {
	.form-wrapper {
		max-width: 800px;
		background-color: $light-blue-color;
		border-radius: 30px;
		padding: 30px;
		.input-wrapper {
			.input-field-wrapper {
				input,
				textarea {
					padding: 10px;
					outline: none;
					border: none;
					height: 50px;
					border-radius: 10px;
					background-color: $bg-color;
					font-family: 'Plus Jakarta Sans';
					font-style: normal;
				}
				textarea {
					max-width: 731px;
					min-height: 120px;
					resize: none;
				}
			}
			button {
				padding: 15px 45px;
				background: transparent;
				border: 1px solid white;
				&:hover {
					background: #eb3c5b;
					border: 1px solid #eb3c5b;
				}
			}
		}
	}
	.address-detail-wrapper {
		.address-detail-svg {
			width: 20%;
		}
		.address-detail-info {
			width: 80%;
		}
	}
}

.about-wrapper {
	margin: 60px 0px 90px 0px;
	.about-content {
		line-height: 40px;
		font-size: 20px;
		letter-spacing: 0.02em;
		opacity: 0.6;
	}
	.goal {
		width: 670px;
		background: $light-blue-color;
		border-radius: 30px;
		padding: 30px;
		.goal-title {
			margin: 0px;
		}
	}
	.core-value-wrapper {
		margin-top: 116px;
		line-height: 30px;
		h3 {
			font-size: 30px;
		}
		h5 {
			margin-bottom: 25px;
			margin-top: 10px;
		}
		ul {
			padding-left: 0px;
		}
	}
}

.refund-wrapper {
	h2 {
		margin-top: 60px;
		line-height: 36px;
		letter-spacing: 0.02em;
	}
}
.stepper-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 50px;
	z-index: 1;

	.stepper-item::after {
		position: absolute;
		content: '';
		border-image: linear-gradient(to right, rgba(235, 60, 91, 1) 85.12%, #0b0b0b 100%) 1;
		border-bottom: 2px solid rgba(235, 60, 91, 1);
		width: 620px;
		top: 343px;
		left: -227px;
		z-index: 100;
		transform: rotate(90deg);
	}
	.stepper-item {
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		&.active {
			svg {
				rect {
					&:nth-child(2) {
						fill: rgb(235, 60, 91) !important;
					}
				}
			}
		}

		svg {
			z-index: 99;
			margin-left: 20px;
		}
	}
}

.stepper-item {
	.step-counter {
		position: relative;
		z-index: 5;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: #ccc;
		margin-bottom: 6px;
	}
}

.stepper-item.activestep {
	font-weight: bold;
}

.stepper-item.firststep .step-counter {
	background-color: #4bb543;
}

.stepper-item.secondstep .step-counter {
	background-color: #4bb543;
}

.stepper-item.thirdstep::after,
.stepper-item.secondstep::after {
	position: unset;
	content: unset;
	border-bottom: unset;
	width: unset;
	top: unset;
	left: unset;
	z-index: unset;
}
.stepper-item:first-child::before {
	content: none;
}
.stepper-item:last-child::after {
	content: none;
}
.hover-pulse {
	transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
	&:hover {
		box-shadow: 0 0 1px 8px rgba(57, 181, 73, 0.4), 0 0 1px 17px rgba(57, 181, 73, 0.2), 0 0 1px 26px rgba(57, 181, 73, 0.2);
	}
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.payment-icon {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.games-page-wrapper {
	margin-top: 120px;
	.games-page-step-card-start {
		margin-top: 70px;
	}
	.games-page-step-card {
		margin-bottom: 40px;
		.games-page-step-card--content {
			padding: 40px;

			p {
				margin-top: 30px;
				font-size: 18px;
				font-weight: 500;
				line-height: 32px;
				margin-bottom: 0px;
			}
			ul {
				li {
					margin: 12px 0px;
				}
			}
		}
	}
	.benifit-icons-wrapper {
		margin-top: 70px;
	}
}

.refer-earn-section {
	margin-top: 100px;
}

.top-game-wrapper {
	gap: 40px;
}
