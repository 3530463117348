.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #000812;
}

.spinner {
	border: 4px solid #eb3c5b;
	border-top: 4px solid #333;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
