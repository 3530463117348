$primary-color: #eb3c5b;
$bg-color: #000812;
$light-black: #1c1c1c;
$white-color: #ffffff;
$light-red-color: #f03b59;
$black-color: #111111;
$dark-color: #0e0e0e;
$grey-color: #9b9b9b;
$blue-color: #0094ff;
$light-blue-color: #192029;
$dark-blue-color: #051121;
$font: 'Plus Jakarta Sans';
